<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

interface Props {
  direction?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'
  darkMode?: boolean
}

withDefaults(defineProps<Props>(), {
  direction: 'bottom-left',
  darkMode: false,
})

const emit = defineEmits<{
  (e: 'change', value: string): void
}>()

const { locale, locales } = useI18n()

const switchLocalePath = useSwitchLocalePath()
const availableLocales = computed(() => {
  const localeList = locales.value as { code: string; name: string }[]
  return localeList.filter(i => i.code !== locale.value)
})
const currentLocale = computed(() => {
  const localeList = locales.value as { code: string; name: string }[]
  return localeList.filter(i => i.code === locale.value)[0]
})

const { changeLocale } = useChatwoot()
watch(locale, (newLocale) => {
  // TODO: should be better implemented
  emit('change', newLocale)
  changeLocale()
})
</script>

<template>
  <Menu as="div" class="relative inline-block text-left">
    <MenuButton
      data-x-action="toggle"
      data-x-name="lang_switcher"
      class="text-sm py-1.5 h-9 transition-colors group hover:text-white hover:bg-blue-500 hover:border-blue-500 px-3.5 flex items-center border-2 rounded-full font-semibold"
      :class="{
        'text-gray-700 border-gray-300': !darkMode,
        'text-white bg-violet-700 border-violet-700': darkMode,
      }"
    >
      <IconNavigationGlobe
        class="hidden w-4 h-4 mr-2 transition-colors sm:block group-hover:text-white"
        :class="{
          'text-gray-400': !darkMode,
          'text-white': darkMode,
        }"
      />
      {{ currentLocale.name }}
      <IconNavigationChevronDown
        class="w-4 h-4 ml-2 transition-colors group-hover:text-white"
        :class="{
          'text-gray-500': !darkMode,
          'text-white': darkMode,
        }"
      />
    </MenuButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute grid w-56 grid-cols-1 text-gray-700 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        :class="{
          'right-0 origin-top-right mt-2': direction === 'bottom-left',
          'left-0 origin-top-left mt-2': direction === 'bottom-right',
          'right-0 bottom-9 origin-bottom-right mb-2': direction === 'top-left',
          'left-0 bottom-9 origin-bottom-left mb-2': direction === 'top-right',
        }"
      >
        <div class="p-1">
          <MenuItem
            v-for="l in availableLocales"
            v-slot="{ active, close }"
            :key="l.code"
          >
            <NuxtLink
              data-x-action="select"
              data-x-name="lang_switcher_item"
              :data-xp-lang="l.code"
              :to="switchLocalePath(l.code)"
              class="flex items-center px-4 py-2 text-sm font-semibold rounded-md"
              :class="{
                'bg-blue-200 text-blue-500': active,
              }"
              @click="handleChange(l.code, close)"
            >
              {{ l.name }}
            </NuxtLink>
          </MenuItem>

          <MenuItem disabled>
            <NuxtLink
              aria-current="page"
              class="flex items-center px-4 py-2 text-sm font-semibold text-gray-400 rounded-md"
            >
              {{ currentLocale.name }}
            </NuxtLink>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
